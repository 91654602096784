@import url("../Themes/DefaultVariables.module.css");

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: var(--full);
  max-width: var(--detailPageMaxWidth);
  gap: var(--gapSm);
}

.activitiesContainer {
  color: var(--whiteText);
  position: relative;
  overflow: hidden;
  width: var(--twentyFivePercent);
  max-width: 350px;
  max-height: 590px;
  text-align: left;
  background-color: var(--regularText);
  border-radius: var(--bsmallBorderRadius);
  overflow: hidden;
}
.activitiesContainer * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.activitiesContainer img {
  max-width: var(--full);
  opacity: 1;
  width: var(--full);
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
.activitiesContainer .activitiesInformation {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--paddingXl) var(--bigPaddingXs);
  width: var(--full);
  height: var(--full);
}
.activitiesContainer .activitiesInformation::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: var(--full);
  border-left: 4px solid rgba(255, 255, 255, 0.8);
  content: "";
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.activitiesContainer h2,
.activitiesContainer p,
.activitiesContainer a {
  margin: var(--marginNull) var(--marginNull) var(--marginXs);
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s, -moz-transform 0.35s,
    -o-transform 0.35s, transform 0.35s;
}
.activitiesContainer h2 {
  word-spacing: -0.15em;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-transform: translate3d(30%, 0%, 0);
  transform: translate3d(30%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.activitiesContainer h2 span {
  font-weight: var(--bold);
}
.activitiesContainer p {
  font-weight: var(--tiny);
  -webkit-transform: translate3d(0%, 30%, 0);
  transform: translate3d(0%, 30%, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.activitiesContainer a {
  font-weight: var(--tiny);
  -webkit-transform: translate3d(0%, 0%, 30%);
  transform: translate3d(0%, 0%, 30%);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.activitiesContainer:hover img {
  opacity: 0.3;
}

.activitiesContainer:hover .activitiesInformation h2 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.activitiesContainer:hover .activitiesInformation p {
  opacity: 0.9;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.activitiesContainer:hover .readMoreButton {
  opacity: 0.9;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.activitiesContainer:hover .activitiesInformation::before {
  background: rgba(255, 255, 255, 0);
  left: 30px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.readMoreButton {
  text-decoration: none;
  color: var(--whiteText);
  font-size: var(--xs);
  font-weight: var(--semiBold);
  padding: var(--paddingMd) var(--paddingLg);
  border: 1px solid var(--whiteText);
  border-radius: var(--bigBorderRadius);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.readMoreButton:hover {
  text-decoration: none;
  color: var(--whiteText);
}

.titleContainer {
  margin-bottom: var(--marginLg);
}

.titleContainer p {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .activitiesContainer {
    width: 35%;
    margin: var(--marginSm);
  }
}

@media screen and (max-width: 930px) {
  .activitiesContainer {
    width: 45%;
    margin: var(--marginSm);
  }
}

@media screen and (max-width: 700px) {
  .activitiesContainer {
    width: 70%;
    margin: var(--marginLg) var(--marginSm);
  }
}

@media screen and (max-width: 575px) {
  .activitiesContainer {
    width: var(--full);
    padding: var(--paddingNull);
    margin: var(--marginSm);
  }
}
