:root {
  /* Colors */

  --primaryColor: #102d33;
  --regularText: #000;
  --whiteText: #fff;
  --pageTitleLine: #5283b5;
  --grayText: #545454;

  /* Border Radius */

  --smallBorderRadius: 5px;
  --mediumBorderRadius: 8px;
  --bigBorderRadius: 12px;

  /* Font Sizes */

  --micro: 12px;
  --xs: 16px;
  --sm: 20px;
  --md: 24px;
  --lg: 32px;
  --xl: 40px;
  --h1: 50px;

  /* Font Weight */

  --tiny: 200;
  --regular: 400;
  --semiBold: 600;
  --bold: 800;

  /* Margin */

  --backgroundImage: -100px;
  --marginNull: 0;
  --marginXs: 5px;
  --marginSm: 10px;
  --marginMd: 15px;
  --marginLg: 20px;
  --marginXl: 30px;

  /* Padding */

  --paddingNull: 0;
  --paddingXs: 5px;
  --paddingSm: 10px;
  --paddingMd: 15px;
  --paddingLg: 20px;
  --paddingXl: 30px;
  --bigPaddingXs: 50px;
  --bigPaddingSm: 80px;
  --bigPaddingMd: 100px;
  --bigPaddingLg: 200px;
  --bigPaddingXl: 300px;

  /* Gap */

  --gapXs: 5px;
  --gapSm: 10px;
  --gapMd: 15px;
  --gapLg: 20px;
  --gapXl: 30px;
  --gapNsLarge: 50px;

  /* Page Sizes */
  --full: 100%;
  --fullScreenHeight: 100vh;
  --fullScreenWidth: 100vw;
  --ninetyFivePercent: 95%;
  --ninetyPercent: 90%;
  --eightyFiveercent: 85%;
  --eightyPercent: 80%;
  --seventyFivePercent: 75%;
  --seventyPercent: 70%;
  --sixtyFivePercent: 65%;
  --sixtyPercent: 60%;
  --fiftyFivePercent: 55%;
  --fiftyPercent: 50%;
  --fortyFivePercent: 45%;
  --fortyPercent: 45%;
  --thirtyFivePercent: 35%;
  --thirtyPercent: 30%;
  --twentyFivePercent: 25%;
  --twentyPercent: 20%;
  --fifteenPercent: 15%;
  --tenPercent: 10%;

  /* Max Width */

  --detailPageMaxWidth: 1550px;
}
