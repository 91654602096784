@import url("../Themes/DefaultVariables.module.css");

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.pageTextContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: center;
  justify-content: center;
}

.pageTexts {
  color: var(--regularText);
  width: var(--full);
  font-size: 40px;
  font-weight: 700;
}

.pageTextContainer h1 {
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notFoundText {
  color: var(--regularText);
  font-size: 252px;
  font-weight: 900;
  text-shadow: -8px 0px 0px #fff;
}

@media screen and (max-width: 650px) {
  .pageTexts {
    font-size: 30px;
  }
  .pageTextContainer h1,
  .notFoundText {
    font-size: 200px;
  }
}

@media screen and (max-width: 450px) {
  .pageTexts {
    font-size: 24px;
  }
  .pageTextContainer h1,
  .notFoundText {
    font-size: 160px;
  }
}
