@import url('../Themes/DefaultVariables.module.css');

.marginTop30 {
  margin-top: var(--marginXl);
  width: var(--full);
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundImage {
  height: 100vh;
  margin-top: var(--backgroundImage);
  width: 100%;
}

.coverImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
}

.banner {
  position: absolute;
  top: 300px;
  left: 200px;
  max-height: 300px;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
}

.bannerTitle {
  font-size: var(--lg);
  font-weight: var(--bold);
  width: var(--full);
  opacity: 1;
  color: var(--whiteText);
}

.bannerText {
  font-size: var(--sm);
  font-weight: var(--semiBold);
  width: var(--full);
  color: var(--whiteText);
}

.placeSuggestionContainer {
  display: flex;
  flex-wrap: wrap;
  width: var(--full);
  align-items: center;
  justify-content: center;
  gap: var(--gapXl);
  margin-top: var(--marginXl);
}

.blockTitleContainer {
  width: var(--full);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.blockTitle {
  width: var(--full);
  color: var(--regularText);
  font-size: var(--md);
  font-weight: var(--semiBold);
  text-align: center;
  display: inline;
}

.shortInformation {
  max-width: 700px;
  color: var(--regularText);
  font-size: var(--sm);
  font-weight: var(--regular);
  text-align: center;
  display: inline;
}

.forestBackground {
  width: var(--full);
}

.activitiesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  display: flex;
  width: var(--full);
  box-sizing: border-box;
  background-color: #d7dcdf;
  min-height: 620px;
  /* padding-inline: 30px; */
}

.blockDivisionContainer {
  width: var(--full);
  max-height: auto;
}

.blockDivisionGrey {
  height: var(--full);
  width: var(--full);
}

.blockDivisionGreen {
  height: var(--full);
  width: var(--full);
  background-color: #d7dcdf;
}

.blockDivisionWave {
  height: var(--full);
  width: var(--full);
  background-color: var(--primaryColor);
}

.activitiesList {
  width: var(--full);
  margin-top: var(--marginXl);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gapSm);
}

.attractionsContainer {
  display: flex;
  flex-wrap: wrap;
  width: var(--full);
  background-color: var(--primaryColor);
  position: relative;
  justify-content: center;
}

.attractionsContainer span {
  color: var(--whiteText);
}

.attractionsContainer .carousel-slider {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.whereToStayContainer {
  width: var(--full);
  height: 500px;
  margin-top: var(--marginLg);
  margin-bottom: var(--marginXl);
}

.loadingContainer {
  height: 100vh;
  width: var(--full);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerBlock {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.activityTextContainer {
  display: inline;
  flex-wrap: wrap;
  max-width: 700px;
}

.activityBlockDescriptionContainer {
  max-width: 700px;
  margin: 0 auto;
}

.whiteColor {
  color: #fff;
}

.w100 {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .attractionsContainer {
    padding: var(--paddingLg) calc(var(--bigPaddingMd) - 20px);
  }
}

@media screen and (max-width: 700px) {
  .attractionsContainer {
    padding: var(--paddingLg) var(--paddingSm);
  }
  .banner {
    width: var(--full);
    left: 0;
    top: 200px;
    padding: var(--paddingSm);
  }
}

@media screen and (max-width: 575px) {
  .activitiesList {
    width: var(--full);
  }
}
