@import url("../Themes/DefaultVariables.module.css");

.container {
  width: var(--full);
}

.blockContainer {
  display: flex;
  flex-wrap: wrap;
  width: var(--full);
  max-width: var(--detailPageMaxWidth);
  margin: 0 auto;
}

.titleContainer {
  min-height: 100px;
  background-color: var(--primaryColor);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: var(--full);
  margin: 20px 0px;
}

.titleContainer h2 {
  font-weight: var(--semiBold);
  color: var(--whiteText);
  font-size: var(--xl);
}

.descriptionContainer {
  width: var(--full);
  border-top: 1px solid var(--grayText);
  border-bottom: 1px solid var(--grayText);
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.titleText {
  margin-bottom: 0;
  font-size: 20px;
  color: var(--regularText);
  font-weight: var(--semiBold);
  display: inline;
}

.anchorText,
.anchorText:hover {
  font-size: 20px;
  text-decoration: none;
  color: var(--pageTitleLine);
  font-weight: var(--semiBold);
}
