@import url('../Themes/DefaultVariables.module.css');

.notScrolling {
  overflow-y: hidden;
}

.fullHeight {
  height: var(--full) !important;
}

.displayBlock {
  display: block !important;
}

.navbarBackground {
  width: var(--full);
  background-color: var(--primaryColor);
  opacity: 0.9;
  height: 100px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  padding: var(--paddingNull) var(--paddingXl);
  position: relative;
}

.navbarLogo {
  height: auto;
  width: 200px;
  padding: 10px;
}

.navbarLogo a {
  display: flex;
  justify-content: center;
  height: var(--full);
  width: var(--full);
}

.logotype {
  height: var(--full);
}

.navbarMenuContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbarComponents {
  display: flex;
  list-style-type: none;
  gap: var(--gapXl);
  margin: var(--marginNull);
  padding: var(--paddingNull);
  height: var(--full);
  align-items: center;
}

.navbarComponents li {
  height: var(--full);
}

.navbarComponents li,
span {
  text-decoration: none;
  color: var(--whiteText);
  font-weight: var(--bold);
  font-size: var(--xs);
}

.navbarComponents li:hover {
  color: gainsboro;
}

.navbarComponents a {
  text-decoration: none;
}

.navbarIcons {
  margin-right: var(--marginMd);
}

.localizationIcon {
  height: var(--full);
  width: var(--full);
}

.profileIcon {
  height: 25px;
  width: 25px;
  margin-right: var(--marginMd);
}

.sideSeengs {
  background: none;
  color: inherit;
  border: none;
  align-self: center;
  height: 100px;
}

.profileIconSection {
  background: none;
  color: inherit;
  border: none;
  align-self: center;
  height: 100px;
}

.localizationSection {
  background: none;
  color: inherit;
  border: none;
  align-self: center;
  height: 100px;
  width: 50px;
}

.localizationIconContainer {
  height: 25px;
  width: 25px;
}

.sideSeengsDisabled {
  pointer-events: none;
}

.profileButtonContainer {
  background: none;
  color: inherit;
  border: none;
  align-self: center;
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  list-style-type: none;
  margin-bottom: var(--marginNull);
  padding-left: var(--paddingNull);
  gap: var(--gapSm);
}

.profileContainer a {
  text-decoration: none;
}

.profileContainer span {
  color: var(--whiteText);
  font-weight: var(--bold);
  font-size: var(--xs);
}

.profileIconContainer {
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  list-style-type: none;
  margin-bottom: var(--marginNull);
  padding-left: var(--paddingNull);
}

.dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--full);
}

.burgerMenuOpened {
  background-color: var(--primaryColor);
  opacity: 0.9;
  height: calc(100vh - 100px);
  width: 350px;
  position: fixed;
  left: 0;
  padding: var(--paddingLg);
  z-index: 22;
  overflow: hidden;
}

.burgerMenuContainer {
  display: none;
}

.burgerButton {
  background: none;
  color: inherit;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.burgerMenuOpened ul {
  list-style-type: none;
}

.burgerMenuOpened a,
span {
  text-decoration: none;
  color: var(--whiteText);
  font-weight: var(--bold);
  font-size: var(--xs);
}

.burgerMenuOpened button {
  padding: var(--paddingNull);
}

.passiveBackground {
  background-color: darkgrey;
  opacity: 0.6;
  height: calc(100vh - 100px);
  width: var(--full);
  position: absolute;
  overflow: hidden;
  z-index: 21;
}

@media screen and (max-width: 1200px) {
  .navbarComponents {
    display: none;
  }

  .profileContainer span {
    display: none;
    pointer-events: none;
  }

  .burgerMenuContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbarLogo {
    margin: var(--marginNull) auto;
  }
  .sideSeengs {
    align-self: flex-start;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  .profileIcon {
    margin-right: var(--marginNull);
  }
}

@media screen and (min-width: 1201px) {
  .burgerMenuOpened {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .burgerMenuOpened {
    width: var(--full);
  }
  .sideSeengs {
    justify-self: flex-start;
  }
  .navbarBackground {
    padding: var(--paddingNull) var(--paddingMd);
  }
}
