@import url("../Themes/DefaultVariables.module.css");

.whereToStayContainer {
  background: url("../../public/Assets/Images/Container_Background/Hotel.webp")
    no-repeat;
  width: var(--full);
  height: var(--full);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 55%;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.toStayTextBlock {
  position: absolute;
  left: 10%;
  top: 10%;
  margin-left: var(--marginLg);
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
}

.toStayText {
  color: var(--regularText);
  text-align: left;
}

.readMore {
  text-decoration: none;
  color: var(--regularText);
  margin-top: var(--marginLg);
  border-radius: var(--mediumBorderRadius);
  padding: var(--paddingSm) var(--paddingMd);
  border: 1px solid var(--regularText);
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: inherit;
  outline: none;
  background: none;
}

.readMore:hover {
  text-decoration: none;
  color: var(--regularText);
}
