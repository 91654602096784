@import url("../Themes/DefaultVariables.module.css");

.container {
  width: var(--full);
  height: var(--full);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.backgroundImage {
  height: 500px;
  margin-top: var(--backgroundImage);
  width: var(--full);
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
}

.titleContainer {
  display: flex;
  width: var(--full);
  align-items: center;
  justify-content: center;
  position: absolute;
}

.titleContainer h1 {
  color: var(--whiteText);
  font-size: var(--h1);
  font-weight: var(--semiBold);
}

.contentContainer {
  width: var(--full);
}

.contentTitleContainer {
  min-height: 100px;
  margin-bottom: var(--marginXl);
  background-color: var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--full);
}

.contentTitleContainer span {
  color: var(--whiteText);
  font-size: var(--xl);
  font-weight: var(--semiBold);
}

.blockContainer {
  margin-top: var(--marginXl);
  width: var(--full);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--marginXl);
  gap: var(--gapSm);
}

.galleryImagesContainer {
  width: var(--full);
  max-width: 1200px;
}

.image-gallery-thumbnails {
  overflow-y: scroll !important;
}
