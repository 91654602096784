.progressiveImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  transition: "opacity .15s linear";
}
