@import url("../Themes/DefaultVariables.module.css");

.footerContainer {
  background-color: var(--primaryColor);
  width: var(--full);
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* position:relative; */
  left: 0;
  bottom: 0;
  right: 0;
}

.footerUpperBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: var(--eightyPercent);
  padding: var(--paddingLg) var(--paddingXl);
  gap: var(--gapLg);
}

.footerBlocks {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.footerBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
}

.footerBlock span {
  width: var(--full);
}

.footerInformativeBlock {
  display: flex;
  flex-wrap: wrap;
  justify-self: flex-start;
  justify-content: space-between;
  gap: var(--gapNsLarge);
}

.footerInformativeBlock span {
  max-width: 300px;
}

.textBlock {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  text-align: left;
}

.textBlock h5 {
  width: var(--full);
  color: var(--whiteText);
}

.footerBlocksLists {
  list-style: none;
  text-align: left;
  padding: var(--paddingNull);
}

.footerBlocksLists a {
  text-decoration: none;
  color: var(--whiteText);
  cursor: pointer;
}

.footerBlocksLists a:hover {
  text-decoration: none;
  color: var(--whiteText);
  cursor: pointer;
}

.imageContainer {
  width: 150px;
  min-width: 150px;
  height: auto;
  max-height: 100px;
  align-self: center;
}

.logo {
  height: var(--full);
  width: var(--full);
}

.copyRightContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: var(--eightyPercent);
  border-top: 0.5px solid #c9bcbc;
  padding-inline: var(--bigPaddingXs);
}

.copyRightContainer a {
  text-decoration: none;
  color: var(--whiteText);
  cursor: pointer;
}

.copyRightContainer a:hover {
  text-decoration: none;
  color: var(--whiteText);
  cursor: pointer;
}

.copyRightContainer span {
  color: var(--whiteText);
  font-size: var(--micro);
  font-weight: var(--regular);
}

.developerLogoContainer {
  height: auto;
  width: 120px;
  display: flex;
  flex-direction: column;
}

.developerLogo {
  height: auto;
  width: var(--full);
}

.developerLogoContainer span {
  color: var(--whiteText);
  font-size: var(--micro);
  font-weight: var(--regular);
}

.textTitle {
  font-size: var(--sm);
  color: var(--whiteText);
  font-weight: var(--semiBold);
  margin-bottom: var(--marginSm);
}

.regularText {
  font-size: var(--xs);
  color: var(--whiteText);
  font-weight: var(--regular);
}

@media screen and (max-width: 1400px) {
  .footerUpperBlock {
    width: var(--full);
    justify-content: center;
  }
  .footerInformativeBlock {
    justify-content: center;
  }
}

@media screen and (max-width: 1120px) {
  .footerInformativeBlock {
    margin-bottom: var(--marginLg);
  }
}

@media screen and (max-width: 950px) {
  .footerInformativeBlock {
    width: var(--full);
  }
  .textBlock {
    width: var(--eightyPercent);
  }
}

@media screen and (max-width: 585px) {
  .copyRightContainer {
    justify-content: center;
    padding-inline: var(--paddingSm);
    width: var(--full);
  }
  .footerUpperBlock {
    width: var(--full);
    justify-content: flex-start;
  }
  .footerInformativeBlock {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 450px) {
  .footerUpperBlock {
    justify-content: flex-start;
  }
  .footerInformativeBlock {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 350px) {
  .footerUpperBlock {
    padding: var(--paddingSm);
  }
  .copyRightContainer {
    justify-content: flex-start;
  }
}

/* Social media icons */

.centeredSocial {
  display: flex;
}
.mediaContainer {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: var(--lg);
}
.mediaContainer h2 {
  color: var(--whiteText);
  font-weight: var(--tiny);
}
.mediaContainer .mediaList {
  padding-left: var(--paddingNull);
}
.mediaContainer li {
  color: rgba(167, 146, 129, 0.6);
  list-style-type: none;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  padding: 1%;
  border: 1px solid rgba(167, 146, 129, 0.4);
  cursor: pointer;
  margin-left: var(--marginSm);
  margin-bottom: var(--marginLg);
  transition: ease 0.3s;
}
.mediaContainer li:hover {
  color: #a79281;
  border: 1px solid #a79281;
}
.mediaList:hover > li {
  opacity: 0.5;
}
.mediaList:hover > li:hover {
  opacity: 1;
}
