@import url("../Themes/DefaultVariables.module.css");

.suggestionContainer {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  max-height: var(--full);
  overflow: hidden;
  position: relative;
}

.suggestionContainerForSmallScreens {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  max-height: var(--full);
  overflow: hidden;
  position: relative;
}

.mediaContainer {
  height: var(--full);
  width: var(--full);
  height: 510px;
}

.offerImage {
  display: block;
  height: var(--full);
  width: var(--full);
}

.offerVideo {
  display: none;
  height: var(--full);
  width: var(--full);
}

.suggestionContainer:hover .offerImage {
  display: none;
}

.suggestionContainer:hover .offerVideo {
  display: block;
}

.hoverEffectContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: var(--full);
  height: 0;
  transition: 0.5s ease;
}

.whiteBackground {
  width: var(--full);
  height: var(--full);
  opacity: 0.5;
}

.suggestionContainer:hover .hoverEffectContainer {
  height: 200px;
  bottom: 0;
}

.offerText {
  font-size: var(--lg);
  font-weight: var(--bold);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin-bottom: var(--marginLg);
  margin-left: var(--marginLg);
}

.suggestionContainer:hover .offerText {
  color: var(--regularText);
  transition: color 0.5s ease-in-out;
}
