@import url("../Themes/DefaultVariables.module.css");

.container {
  width: 100%;
  max-width: var(--detailPageMaxWidth);
  padding: 0px 10px;
  margin: var(--marginNull) auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--marginXl);
}

.componentContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: var(--fiftyPercent);
  max-width: 660px;
  min-width: 565px;
  min-height: 300px;
  max-height: 750px;
  border-radius: var(--smallBorderRadius);
  overflow: hidden;
  box-shadow: 0 1rem 1rem #808080;
  transition: 0.2s;
}

.imageContainer {
  width: 55%;
  height: var(--full);
  height: 400px;
}

.image {
  height: var(--full);
  width: var(--full);
  min-height: 300px;
}

.informationContainer {
  width: 45%;
  padding: var(--paddingSm);
  align-self: center;
}

.titleContainer {
  width: var(--full);
  display: flex;
  flex-wrap: wrap;
}

.titleContainer span {
  font-size: var(--md);
  color: var(--regularText);
  font-weight: var(--semiBold);
}

.textBlocks {
  width: var(--full);
  display: flex;
  flex-wrap: nowrap;
  margin-top: var(--marginSm);
  border-bottom: 1px solid #808080;
  height: 45px;
  gap: var(--gapSm);
}

.textBlocks span {
  max-width: var(--eightyPercent);
  color: var(--regularText);
  font-size: var(--xs);
  font-weight: var(--semiBold);
  height: var(--full);
  display: flex;
  align-items: center;
  text-align: left;
}

.icon {
  height: 30px;
  width: 30px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--marginMd);
}

.button {
  text-decoration: none;
  color: var(--regularText);
  padding: var(--paddingSm) var(--paddingMd);
  display: flex;
  gap: var(--gapXs);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.buttonContainer span {
  color: var(--regularText);
  font-size: var(--xs);
}

.button:hover {
  text-decoration: none;
  color: var(--regularText);
  gap: var(--gapSm);
}

@media screen and (max-width: 1320px) {
  .imageContainer {
    width: var(--full);
    max-width: 370px;
  }
  .informationContainer {
    width: var(--full);
  }
  .componentContainer {
    min-width: 0;
    max-width: 370px;
  }
}

@media screen and (max-width: 650px) {
  .componentContainer {
    width: var(--full);
  }
  .container {
    padding: var(--paddingNull) var(--paddingSm);
  }
}
