@import url("../Themes/DefaultVariables.module.css");

.dropdownItemHidden {
  display: none;
}

.dropdownItemVisiable {
  display: block;
  position: absolute;
  min-width: 450px;
  min-height: 100px;
  background-color: var(--primaryColor);
  opacity: 0.9;
  padding: var(--paddingSm) var(--paddingSm) var(--paddingNull) var(--paddingSm);
}

.dropdownItemVisiable a {
  text-decoration: none;
  color: var(--whiteText);
}

.dropdownItemVisiable a:hover {
  color: gainsboro;
}

.dropdownContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.dropdownLeftBlock {
  flex-direction: column;
  width: 60%;
}

.dropdownRightBlock {
  flex-direction: column;
  width: 40%;
}

.dropdownContainer p {
  font-size: var(--xs);
  font-weight: var(--semiBold);
}

@media screen and (max-width: 1200px) {
  .dropdownItemVisiable {
    display: block;
    padding: var(--paddingXl) var(--paddingMd) var(--paddingSm) var(--paddingMd);
    position: relative;
    min-width: 0px;
    min-height: 0px;
    padding: var(--paddingXl) var(--paddingMd) var(--paddingNull)
      var(--paddingMd);
  }
  var(--bigPaddingLg) .dropdownContainer {
    display: block;
    flex-direction: column;
  }
  .dropdownItemVisiable a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .dropdownLeftBlock,
  .dropdownRightBlock {
    width: var(--full);
  }
}
