@import url('../../Themes/DefaultVariables.module.css');


.backgroundImage {
    height: 500px;
    width: var(--full);
    margin-top: var(--backgroundImage);  
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;  
}

.coverImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

}

.backgroundImage h1 {
    font-size: var(--xl);
    font-weight: var(--semiBold);
    position: absolute;
    color: var(--whiteText)
}

.descriptionContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    width: var(--full);
    padding: var(--paddingSm) var(--paddingLg);
}

.environmentDescription {
    width: var(--fiftyPercent);
    border-right: 1px solid #cdcdcd;
    display: inline;
    flex-wrap: wrap;
    padding: var(--paddingSm);
    text-align: left;
}

.blockTitle {
    font-size: var(--md);
    color: var(--regularText);
    font-weight:var(--semiBold);
    width: var(--full);
}

.blockText {
    font-size: var(--sm);
    color: var(--regularText);
    font-weight: var(--regular);
    width: var(--full);
    text-align: left;
    white-space: pre-line
}

.activityInformation {
    width: var(--fiftyPercent);
    display: inline;
    text-align: left;
    flex-wrap: wrap;
    padding: var(--paddingSm);
}

.suggestionsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start ;
    justify-content: flex-start;
    max-width: 1200px;
    padding: var(--paddingSm) var(--paddingXl);
    border-top: 1px solid #cdcdcd;
    /* border-bottom: 1px solid #cdcdcd; */
    width: var(--full);
    min-height: 100px;
    margin-bottom: var(--marginLg);

}

.sectionTitle {
    font-size: var(--lg);
    color: var(--regularText);
    font-weight: var(--semiBold);
    text-align: left;
    margin-bottom: var(--marginSm);
    border-bottom: 1px solid #808080;
}

.sectionText {
    font-size: var(--sm);
    color: var(--regularText);
    font-weight: var(--semiBold);
    width: var(--full);
    text-align: left;

}

.suggestedActivityContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gapSm);
    width: var(--fiftyPercent);
}

.suggestedActivityIcon {
    height: 20px;
    width: 20px;
}

.socialMediaBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gapMd);
    flex-wrap: wrap;
    width: var(--full);
    border-bottom: 1px solid #cdcdcd;
    border-top: 1px solid #cdcdcd;
    height: 100px;
    max-width: 1200px;
    padding: var(--paddingSm) var(--paddingXl);
    margin-bottom: var(--marginLg);
}

.socialMediaBlock span {
    color: var(--regularText);
    font-size: var(--sm);
    font-weight: var(--semiBold);
}


.sliderImage {
    height: var(--full);
    width: var(--full);
    max-height: 600px;
}

.mapContainer {
    height: 100px;
    border-top: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: var(--full);
    padding: var(--paddingSm) var(--paddingXl);
}

.mapButton {
    background-color: inherit;
    background: none;
    border: none;
    outline: none;
    color: var(--regularText);
    font-weight: var(--semiBold);
    text-align: center;
    align-items: center;
    display: flex;
    padding: var(--paddingNull);
}

.locationIcon {
    width: 25px;
}

.mapText {
    color: #808080;
    font-weight: var(--semiBold);
}

.informationContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--marginXl);
    max-width: 1200px;
}

.informationHistoryContainer {
    display: flex;
    flex-wrap: wrap;
    border-right: 1px solid #cdcdcd;
    width: var(--fiftyPercent);
}

.historyTitle {
    color: var(--regularText);
    font-size: var(--sm);
    font-weight: var(--semiBold);
    text-align: center;
    width: var(--full);
    margin-bottom: var(--marginSm);
}

.historyText {
    color: var(--regularText);
    font-size: var(--xs);
    font-weight: var(--semiBold);
    text-align: left;
    width: var(--full);
}

.generalInformationContainer{
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #cdcdcd;
    width: var(--fiftyPercent);
}

.singleBlockContainer {
    width: var(--full);
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #cdcdcd;
    min-height: 40px;
    padding: var(--paddingXs) var(--paddingSm);
}

.generalInformationTitle {
    color: var(--regularText);
    font-weight: var(--semiBold);
    font-size: var(--sm);
    width: var(--full);
    text-align: center;
}

.nameBlock {
    display: flex;
    flex-wrap: nowrap;
}

.generalInformationName {
    width: 95px;
    color: var(--regularText);
    font-weight: var(--semiBold);
    font-size: var(--xs);
    text-align: left;
    margin-left: var(--marginSm);
}

.generalInformation {
    color: var(--regularText);
    font-weight: var(--regular);
    font-size: var(--xs);
}

.generalInformationIcon {
    height: 20px;
    width: 20px;
}



@media screen and (max-width: 650px) {
    .environmentDescription {
        width: var(--full);
        border-right: 0;
        border-bottom: 1px solid #cdcdcd;
    }

    .activityInformation {
        width: var(--full);
        border-left: 0;
        border-top: 1px solid #cdcdcd;
    }
}


@media screen and (max-width: 1170px) {
    .informationHistoryContainer {
        width: var(--full);
        border-right: 0;
    }
    .generalInformationContainer {
        width: var(--full);
        border-left: 0;
    }
}

@media screen and (max-width: 600px) {
    .socialMediaBlock {
        justify-content: center;
    }
    .mapContainer {
        justify-content: center;
    }
}

@media screen and (max-width: 460px) {
    .generalInformation {
        width: var(--full);
    }
}

@media screen and (max-width: 600px) {
    .suggestedActivityContainer {
        width: var(--full);
    }
}