@import url("../Themes/DefaultVariables.module.css");

.navBarComponent {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .navBarComponent {
    justify-content: flex-start;
  }
}
