@import url("../Themes/DefaultVariables.module.css");

.container {
  width: var(--full);
  max-width: var(--detailPageMaxWidth);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: auto;
  gap: var(--gapLg);
}

.card {
  display: block;
  border-radius: var(--smallBorderRadius);
  width: var(--twentyFivePercent);
  max-width: 350px;
  min-width: 330px;
  overflow: hidden;
  box-shadow: 0 1rem 1rem #808080;
  transition: 0.2s;
  min-height: 550px;
  max-height: 590px;
}

.imageContainer {
  height: 200px;
  width: var(--full);
}

.image {
  height: var(--full);
  width: var(--full);
}

.textInformationBlock {
  width: var(--full);
  display: block;
  padding: var(--paddingSm);
}

.titleContainer {
  width: var(--full);
  min-height: 80px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
}

.titleContainer h1 {
  font-size: var(--md);
  color: var(--regularText);
  font-weight: var(--semiBold);
}

.textContainer {
  height: 190px;
  width: var(--full);
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: var(--marginLg);
}

.textContainer span {
  color: var(--regularText);
  font-size: var(--xs);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--regular);
  width: var(--full);
  height: var(--full);
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

.button {
  padding: var(--paddingSm) var(--paddingMd);
  text-decoration: none;
  align-items: center;
  justify-content: center;
  gap: var(--gapSm);
  color: var(--regularText);
  border: 1px solid #808080;
  margin-top: var(--marginSm);
  border-radius: var(--mediumBorderRadius);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: var(--marginSm);
}

.button:hover {
  color: var(--regularText);
}

@media screen and (max-width: 350px) {
  .card {
    min-width: 0;
    width: var(--full);
    height: auto;
  }
  .titleContainer {
    height: auto;
  }
}
