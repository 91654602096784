@import url('../../Themes/DefaultVariables.module.css');

.dropdownItemHidden {
    display: none;

}

.dropdownItemVisiable {
    display: block;
    position: absolute;
    min-width: 450px;
    min-height: 150px;
    background-color: var(--primaryColor);
    opacity: 0.9;
    padding: var(--paddingSm) var(--paddingMd) var(--paddingNull) var(--paddingMd);
}

.dropdownLocalization {
    display: block;
    position: absolute;
    width: 50px;
    height: auto;
    background-color: var(--primaryColor);
    opacity: 0.9;
    padding: var(--paddingXs);
    gap: var(--gapSm);
}

.languageFlag {
    border-radius: var(--smallBorderRadius);
    overflow: hidden;
}
 
 .dropdownItemVisiable a {
    text-decoration: none;
    color: var(--whiteText);
}

 .dropdownItemVisiable a:hover {
    color:gainsboro;
}

.localizationItem {
    width: var(--full);
}

.dropdownContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.localizationDropdownContainer {
    gap: var(--gapXs);
}

.dropdownBlock {
    flex-direction: column;
    width: var(--fiftyPercent);
}

.dropdownBlock p {
    font-size: var(--xs);
    font-weight: var(--bold);
}


@media screen and (max-width: 1200px) {
        .dropdownItemVisiable {
        display: block;
        padding: var(--paddingXl) var(--paddingMd) var(--paddingSm) var(--paddingMd);
        position: relative;
        min-width: 0px;
        min-height: 0px;
        padding: var(--paddingXl) var(--paddingMd) var(--paddingNull) var(--paddingMd);
    }
    .dropdownContainer {
        flex-direction: column;
    }
    .dropdownItemVisiable a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .dropdownBlock {
        width: var(--full);
    }
}


.profileDropdownHidden {
    display: none;
}

.profileDropdownVisiable {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    min-width: 200px;
    min-height: 150px;
    right: 0;
    background-color: var(--primaryColor);
    opacity: 0.9;
    padding: var(--paddingSm) var(--paddingMd);
    justify-content: flex-start;
    z-index: 100;
}

.dividerLine {
    width: var(--full);
    color: var(--whiteText);
    margin-top: var(--marginMd);
    margin-bottom: var(--marginMd);
}

.toHelpPage, .toHelpPage:focus, .toHelpPage:active {
    background: none;
    border: none;
    outline: none;
    align-self: center;
    width: var(--full);
    height: 40px;
    color: var(--whiteText);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: var(--paddingSm);
    padding-right: var(--paddingSm);
    font-weight: var(--bold);
}


.toHelpPage:hover {
    background-color: transparent;
    color: var(--whiteText);
}

.authButtonContainer {
    width: var(--full); 
}

.authButton {
    height: 40px;
    background-color: inherit;
    border: none;
    outline: none;
    color: var(--whiteText);
    font-weight: var(--bold);
    text-align: left;
    width: var(--full);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
