@import url("../Themes/DefaultVariables.module.css");

.carouselContainer {
  width: var(--full);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 20;
}

.sliderPrevButton {
  background: none;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: var(--fiftyPercent);
  left: 0;
  z-index: 10;
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sliderNextButton {
  background: none;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: var(--fiftyPercent);
  right: 0;
  z-index: 10;
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.carouselContainer:hover .sliderNextButton {
  display: block;
}

.carouselContainer:hover .sliderPrevButton {
  display: block;
}

.slider-wrapper ul {
  height: 250px !important;
}

.carouselPosition {
  z-index: -1;
}
