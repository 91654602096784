@font-face {
  font-family: "Montserrat-VariableFont";
  src: local("Montserrat-VariableFont"),
    url(../public/Assets/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf)
      format("truetype");
}

body {
  margin: 0;
  font-family: Montserrat-VariableFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
}

code {
  font-family: Montserrat-VariableFont, monospace;
}
