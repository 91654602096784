@import url("../Themes/DefaultVariables.module.css");

.attractionsContainer {
  display: flex;
  flex-wrap: wrap;
  width: var(--full);
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}

.sliderContentContainer {
  display: flex;
  flex-wrap: wrap;
  width: var(--full);
  justify-content: center;
  gap: var(--gapLg);
}

.attractionsImage {
  width: 40% !important;
  height: auto;
}

.imageSlider {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: var(--full);
}

.imageSlider .carousel-slider {
  margin: var(--marginNull) auto;
}

.attractionsStoryContainer {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.attractionsStoryContainer span {
  width: var(--full);
}

.articleTitle {
  font-size: var(--sm);
  font-weight: var(--bold);
  text-align: center;
}

.articleText {
  font-size: var(--xs);
  font-weight: var(--semiBold);
  text-align: left;
}

.moreInformation {
  text-decoration: none;
  border: 1px solid var(--whiteText);
  border-radius: var(--mediumBorderRadius);
  padding: var(--paddingSm) var(--paddingMd);
  color: var(--whiteText);
}

.moreInformation:hover {
  text-decoration: none;
  color: var(--whiteText);
}

@media screen and (max-width: 1450px) {
  .attractionsImage {
    width: var(--fiftyPercent) !important;
    height: auto;
  }
}

@media screen and (max-width: 1100px) {
  .attractionsImage {
    width: 60% !important;
    height: auto;
  }
  .attractionsStoryContainer {
    width: var(--full);
  }
}

@media screen and (max-width: 900px) {
  .attractionsImage {
    width: var(--eightyPercent) !important;
    height: auto;
  }
  .attractionsStoryContainer {
    width: var(--full);
  }
}

@media screen and (max-width: 600px) {
  .attractionsImage {
    width: var(--full) !important;
    height: auto;
  }
  .attractionsStoryContainer {
    width: var(--full);
  }
}
