@import url("../Themes/DefaultVariables.module.css");

.hotelsCradsContainer {
  width: var(--full);
  padding: var(--paddingSm) var(--paddingLg);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.hotelCard {
  max-width: 400px;
  border-radius: 12px;
  overflow: hidden;
  border: none;
  outline: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 5px 10px 5px;
}

.hotelsCardBody {
  padding-top: var(--paddingXs) !important;
  padding: var(--paddingNull);
}

.hotelsImagesContainer {
  width: var(--full);
  height: auto;
  border-radius: var(--bigBorderRadius);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.hotelDetailsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: var(--full);
  gap: var(--gapSm);
}

.hotelMainRequisitesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 25px;
  /* margin-bottom: var(--marginSm); */
}

.hotelDescriptionContainer {
  padding-bottom: var(--paddingXs);
}
.hotelTitleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: var(--paddingNull) var(--paddingSm);
  gap: var(--gapSm);
}

.hotelTitle {
  font-size: var(--sm);
  font-weight: var(--semiBold);
  color: var(--regularText);
}

.hotelFeedbackMarkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  gap: var(--gapSm);
}

.hotelFeedbackMark {
  font-size: var(--sm);
  font-weight: var(--semiBold);
  color: var(--regularText);
  text-align: center;
}

.hotelFeedbackIcon {
  fill: var(--regularText);
  height: 15px;
  width: 15px;
  align-self: center;
}

.hotelLocationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--marginSm);
}

.hotelLocation {
  font-size: var(--xs);
  font-weight: var(--regular);
  color: #7d7474;
}

.hotelAdditionalInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}

.aveilabilityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 55%;
  gap: var(--gapSm);
}

.hotelAvailability {
  font-size: var(--xs);
  font-weight: var(--regular);
  color: #7d7474;
  align-self: flex-start;
}

.pricePreNight {
  font-size: var(--xs);
  font-weight: var(--regular);
  color: var(--regularText);
  align-self: flex-start;
}

.hotelBookingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: var(--full);
  width: 45%;
  min-height: 60px;
}

.bookingButton {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--whiteText);
  height: var(--full);
  width: var(--full);
  padding-inline: var(--paddingSm);
  align-self: flex-end;
  justify-self: flex-end;
  min-height: 40px;
  background-color: #989898;
  border-radius: var(--smallBorderRadius);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.hotelInformationBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px;
  gap: 10px;
}

.hotelInformationTitle {
  color: var(--grayText);
  font-weight: 800;
}

.hotelInformationText,
.hotelInformationText:hover {
  color: var(--regularText);
  text-decoration: none;
}
