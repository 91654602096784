@import url("../Themes/DefaultVariables.module.css");

.backgroundImage {
  height: 70vh;
  width: auto;
  margin-top: var(--backgroundImage);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.coverImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.placeTextContainer {
  flex-direction: row;
  padding-left: var(--paddingXl);
  padding-right: var(--paddingXl);
  position: absolute;
  max-width: 600px;
}

.placeTextColumn {
  flex-direction: column;
  width: 100%;
}

.placesToStayIcon {
  height: 60px;
  width: 60px;
}

.placesTextContainer {
  margin-top: var(--marginXl);
}

.placesPrimaryText {
  font-weight: var(--bold);
  color: var(--whiteText);
  letter-spacing: 0.2em;
}

.placesRegularText {
  font-size: var(--sm);
  font-weight: var(--semiBold);
  color: var(--whiteText);
}

.placesBody {
  width: var(--full);
  max-width: var(--detailPageMaxWidth);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  justify-self: center;
  margin: 0 auto;
  margin-top: var(--marginXl);
  margin-bottom: var(--marginXl);
}

.placesFilterRow {
  flex-direction: row;
  border-top: 3px solid #b4b4b4;
  border-bottom: 3px solid #b4b4b4;
  min-height: 100px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  width: var(--full);
}

.placesFilterContainer {
  flex-direction: column;
  width: 70%;
  display: flex;
}

@media screen and (max-width: 1550px) {
  .placesBody {
    padding: var(--paddingXl) var(--bigPaddingSm);
  }
}

@media screen and (max-width: 900px) {
  .placeTextColumn {
    width: var(--fiftyPercent);
  }
}

@media screen and (max-width: 550px) {
  .placesBody {
    padding: var(--paddingNull);
  }
  .placeTextColumn {
    width: var(--full);
  }
  .backgroundImage {
    padding-top: var(--bigPaddingLg + 50px);
  }
  .placesFilterContainer {
    width: var(--full);
  }
}
